<template>
  <v-container>
    <!--  -->
    <base-event />
  </v-container>
</template>

<script>
  export default {}
</script>

<style></style>
